const USER_DATA = {
  id: "1",
  type: "users",
  firstName: "admin",
  lastName: "admin",
  gender: "male",
  dob: null,
  email: "admin@example.com",
  phone: null,
  mobile: null,
  fax: null,
  cvUrl: null,
  awr: false,
  dda: "Unknown",
  ethnicOrigin: { id: 6, label: "White and Black African" },
  countryOfBirth: { id: "XXX", label: "Unknown" },
  incomeType: null,
  complianceChecks: [],
  complianceSupportingFile: null,
  website: null,
  isBilling: false,
  accountType: null,
  approverLevelNbr: null,
  roles: ["super admin"],
  addresses: [],
  organisations: null,
  intermediaryOrganisation: null,
  events: []
};

export const MOCKED_AUTH_STATE = {
  userData: USER_DATA,
  userToken: "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9",
  isAuthenticating: false,
  isLoadingSelfDetails: false,
  authenticatingError: null
};
