/* eslint-disable prettier/prettier */
export const SET_IS_FETCHING_INVOICES = "SET_IS_FETCHING_INVOICES";
export const SET_IS_FETCHING_PAYROLL = "SET_IS_FETCHING_PAYROLL";
export const SET_INVOICES = "SET_INVOICES";
export const SET_PAYROLL = "SET_PAYROLL";
export const SET_CURRENT_INVOICE = "SET_CURRENT_INVOICE";
export const SET_NEW_INVOICE = "SET_NEW_INVOICE";
export const SET_NEW_PAYROLL = "SET_NEW_PAYROLL";
export const SET_INVOICE_FILTERS = "SET_INVOICE_FILTERS";
export const SET_PAYROLL_FILTERS = "SET_PAYROLL_FILTERS";

export const SET_PAYROLL_ITEMS = "SET_PAYROLL_ITEMS";

// external mutation types
export const NS_SET_FETCHING_INVOICES_ERROR =
  "invoices/SET_FETCHING_INVOICES_ERROR";
