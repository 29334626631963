import api from "../../../services/modules/invoices";
import { isArray } from "lodash";
import {
  SET_INVOICES,
  SET_IS_FETCHING_INVOICES,
  SET_IS_FETCHING_PAYROLL,
  SET_PAYROLL,
  SET_NEW_INVOICE,
  SET_NEW_PAYROLL,
  SET_INVOICE_FILTERS,
  SET_PAYROLL_FILTERS,
  SET_PAYROLL_ITEMS
} from "./mutation-types";
import {
  GENERATE_INVOICES,
  GENERATE_PAYROLL_ITEM,
  GET_ALL_INVOICES,
  GET_PAYROLL
} from "./action-types";
import {
  handleWithErrorMessage,
  addGlobalMessage,
  errorHandler
} from "@/services/utils";
import { setStoreValue } from "@/store/utils";
import { isMockedDataEnabled } from "@/utils/mocked-data";
import { MOCKED_FINANCE_STATE } from "@/constants/finance";

const state = {
  payroll: [],
  invoices: [],
  isFetchingInvoices: false,
  isFetchingPayroll: false,
  payrollFilters: {},
  invoiceFilters: {}
};

const actions = {
  async [GET_ALL_INVOICES]({ commit, dispatch }, params) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getAllInvoices(params),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_INVOICES, isLoading),
      setDataFunction: ({ data }) => commit(SET_INVOICES, data)
    });
  },
  async [GET_PAYROLL]({ commit, dispatch }, params) {
    return handleWithErrorMessage({
      dispatch,
      request: () => api({ commit }).getPayroll(params),
      setLoadingFunction: isLoading =>
        commit(SET_IS_FETCHING_PAYROLL, isLoading),
      setDataFunction: ({ data }) => commit(SET_PAYROLL_ITEMS, data)
    });
  },
  async [GENERATE_INVOICES]({ commit, dispatch }, newInvoice) {
    try {
      const { data, meta } = await api({ commit }).generateInvoice(newInvoice);
      addGlobalMessage(dispatch, { type: "success", meta });
      if (data) {
        commit(SET_NEW_INVOICE, data);
      }
      return data;
    } catch (exception) {
      errorHandler(dispatch, exception);
    }
  },
  async [GENERATE_PAYROLL_ITEM]({ commit, dispatch }, newPayroll) {
    try {
      const { data, meta } = await api({ commit }).generatePayroll(newPayroll);
      addGlobalMessage(dispatch, { type: "success", meta });
      if (data) {
        commit(SET_NEW_PAYROLL, data);
      }
      return data;
    } catch (exception) {
      errorHandler(dispatch, exception);
    }
  }
};

const mutations = {
  [SET_INVOICES]: setStoreValue("invoices"),
  [SET_PAYROLL]: setStoreValue("payroll"),
  [SET_INVOICE_FILTERS]: setStoreValue("invoiceFilters"),
  [SET_PAYROLL_FILTERS]: setStoreValue("payrollFilters"),
  [SET_IS_FETCHING_INVOICES]: setStoreValue("isFetchingInvoices"),
  [SET_IS_FETCHING_PAYROLL]: setStoreValue("isFetchingPayroll"),

  [SET_PAYROLL_ITEMS](state, payload) {
    state.payroll = payload; // Update the state with the fetched payroll items
  },

  [SET_NEW_INVOICE](state, invoice) {
    return isArray(invoice)
      ? state.invoices.push(...invoice)
      : state.invoices.push({ ...invoice });
  },
  [SET_NEW_PAYROLL](state, payroll) {
    return isArray(payroll)
      ? state.payroll.push(...payroll)
      : state.payroll.push({ ...payroll });
  }
};

export default {
  namespaced: true,
  state: isMockedDataEnabled() ? MOCKED_FINANCE_STATE : state,
  actions: isMockedDataEnabled() ? {} : actions,
  mutations: isMockedDataEnabled() ? {} : mutations
};
